<!--<div class="crossIcon" (click)="closePrivacy();"></div>-->
<div class="title">
  {{"PRIVACY_POLICY.PRIVACY"|translate}}
  <span>{{"PRIVACY_POLICY.POLICY"|translate}} </span>
</div>
<div class="content">

  {{"PRIVACY_POLICY.Global_Teams_Ltd_Terms_and_Privacy_Policy_describes"|translate}}

  <br/><br/>
    <span class="subTitle">
      {{"PRIVACY_POLICY.Use_of_Your_Personal_Information"|translate}}
    </span>
    <br/>
    {{"PRIVACY_POLICY.When_you_visit_the_Site"|translate}}
    {{"PRIVACY_POLICY.Log_files_track_actions"|translate}}

  <br/><br/><span class="subTitle">{{"PRIVACY_POLICY.How_Do_We_Use_Your_Personal_Information"|translate}}</span><br/>
  {{"PRIVACY_POLICY.The_Personal_Information_we_collect_from_you"|translate}}

  <br/><br/> <span class="subTitle">{{"PRIVACY_POLICY.Do_Not_Track"|translate}}</span><br/>
    {{"PRIVACY_POLICY.Please_note_that_we_do_not_alter_our_Site"|translate}}

  <br/><br/> <span class="subTitle">{{"PRIVACY_POLICY.Blocking_Cookies"|translate}}</span><br/>
    {{"PRIVACY_POLICY.You_can_block_cookies_by_changing_the_setting"|translate}}
  <br/><br/>
  <span class="subTitle">{{"PRIVACY_POLICY.For_European_Residents"|translate}}</span><br/>
    {{"PRIVACY_POLICY.If_you_are_a_European_resident"|translate}}

  <br/><br/> <span class="subTitle">
    {{"PRIVACY_POLICY.Data_Retention_Period"|translate}}
    </span><br/>
    {{"PRIVACY_POLICY.When_you_fill_in_contact_form"|translate}}

  <br/><br/> <span class="subTitle">
    {{"PRIVACY_POLICY.Childrens_Online_Privacy_Protection_Act_Compliance"|translate}}
  </span><br/>
  {{"PRIVACY_POLICY.We_are_in_compliance_with_requirements_of_COPPA"|translate}}

  <br/><br/> <span class="subTitle">
    {{"PRIVACY_POLICY.Your_Consent"|translate}}
  </span><br/>
  {{"PRIVACY_POLICY.By_using_our_Site_you_consent"|translate}}

  <br/><br/> <span class="subTitle">
    {{"PRIVACY_POLICY.Changes_to_Our_Privacy_Policy"|translate}}</span><br/>

  {{"PRIVACY_POLICY.We_may_update_these_cookies_and_privacy_policy"|translate}}

  <br/><br/>
  {{"PRIVACY_POLICY.Global_Teams_Dev_Ltd_Tel_Aviv_Yafo"|translate}}

</div>

<ng-container *ngIf="show">
  <div class="cookiesBar" [ngClass]="{'fadeOut': fadeOut}">
    <div class="message">
      {{"COOKIES_BAR.This_site_uses_cookies"|translate}}
      {{"COOKIES_BAR.By_using_our_site"|translate}}
      <a [routerLink]="'/terms'">
        {{"COOKIES_BAR.Terms_and_Privacy_Policy"|translate}}
      </a>
    </div>
    <div class="button" (click)="acceptCookies()">
      {{"COOKIES_BAR.Agree"|translate}}
    </div>

    <svg class="closeCross" (click)="closeCookie()" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.646447" y1="15.6665" x2="15.7239" y2="0.589031" stroke="white"/>
      <line x1="15.7236" y1="15.4309" x2="0.646159" y2="0.353512" stroke="white"/>
    </svg>


  </div>
</ng-container>

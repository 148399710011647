import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {PrivacyComponent} from '../../pages/privacy/privacy.component';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {

  public show = false;
  public fadeOut = false;


  constructor(public dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: Object) {
  }


  // openPrivacy(): void {
  //   const dialogRef = this.dialog.open(PrivacyComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }

  ngOnInit(): void {
    if (localStorage && localStorage.getItem('acceptCookies') == null) {
      this.show = true;
    }
  }

  closeCookie():void {
    this.fadeOut = true;
  }
  acceptCookies(): void {
    localStorage.setItem('acceptCookies', 'true');
    this.fadeOut = true;

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.show = false;
      }, 500);
    }

  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/company/about-us/about-us.component';
import { TrustedByComponent } from './pages/company/trusted-by/trusted-by.component';
import { HowItWorksComponent } from './pages/company/how-it-works/how-it-works.component';
import { OurLeadersComponent } from './pages/company/our-leaders/our-leaders.component';
import { WhyGlobalTeamsComponent } from './pages/company/why-global-teams/why-global-teams.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { DedicatedTeamsComponent } from './pages/services/dedicated-teams/dedicated-teams.component';
import { UiUxComponent } from './pages/services/ui-ux/ui-ux.component';
import { ManagedTeamsComponent } from './pages/services/managed-teams/managed-teams.component';
import { EndToEndProjectsComponent } from './pages/services/end-to-end-projects/end-to-end-projects.component';
import { SpecialistSupportComponent } from './pages/services/specialist-support/specialist-support.component';
import { QAComponent } from './pages/services/qa/qa.component';
import { WorkingAtGlobalComponent } from './pages/career/working-at-global/working-at-global.component';
import { SupportingComponent } from './pages/services/supporting/supporting.component';
import { OpenPositionsComponent } from './pages/career/open-positions/open-positions.component';
import { JobViewerComponent } from './pages/career/open-positions/job-viewer/job-viewer.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { WhyEastCentralEuropeComponent } from './pages/company/why-east-central-europe/why-east-central-europe.component';
import { IndustriesWeServeComponent } from './pages/company/industries-we-serve/industries-we-serve.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'COMMON.HOME_TITLE_TAG',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'company/about-us',
    component: AboutUsComponent,
    data: {
      title: 'COMMON.ABOUT_US_TITLE_TAG',
      metaDescriptionTag: 'COMMON.ABOUT_US_Meta_Description'
    }
  },
  //{path: 'company/how-it-works', component: HowItWorksComponent},
  // {
  //   path: 'company/our-leaders',
  //   component: OurLeadersComponent,
  //   data: {
  //     title: 'OUR_LEADERS.Our_Leaders',
  //     metaDescriptionTag: 'COMMON.HOME_Meta_Description'
  //   }
  // },
  // {
  //   path: 'company/trusted-by',
  //   component: TrustedByComponent,
  //   data: {
  //     title: 'TRUSTED_BY.Why_organizations_choose_Global_Teams',
  //     metaDescriptionTag: 'COMMON.HOME_Meta_Description'
  //   }
  // },
  {
    path: 'company/why-global-teams',
    component: WhyGlobalTeamsComponent,
    data: {
      title: 'WHY_GLOBAL_TEAMS.TITLE_TAG',
      metaDescriptionTag: 'WHY_GLOBAL_TEAMS.Meta_Description'
    }
  },
  {
    path: 'company/why-east-central-europe',
    component: WhyEastCentralEuropeComponent,
    data: {
      title: 'COMMON.why_east_central_europe_TITLE_TAG',
      metaDescriptionTag: 'COMMON.why_east_central_europe_Meta_Description'
    }
  },
  {
    path: 'company/industries-we-serve',
    component: IndustriesWeServeComponent,
    data: {
      title: 'Industries_we_serve.TITLE_TAG',
      metaDescriptionTag: 'Industries_we_serve.Meta_Description'
    }
  },
  {
    path: 'contact-us',
    component: ContactUsPageComponent,
    data: {
      title: 'COMMON.Our_Offices',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'services/extended-teams',
    component: DedicatedTeamsComponent,
    data: {
      title: 'SERVICES.Extended_team.TITLE_TAG',
      metaDescriptionTag: 'SERVICES.Extended_team.Meta_Description'
    }
  },
  {
    path: 'services/ui-ux',
    component: UiUxComponent,
    data: {
      title: 'HEADER.UI_UX',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'services/managed-teams',
    component: ManagedTeamsComponent,
    data: {

      title: 'SUPPORTING_SERVICES.Dedicated_Teams_TITLE_TAG',
      metaDescriptionTag: 'SUPPORTING_SERVICES.Dedicated_Teams_Meta_Description'
    }
  },
  {
    path: 'services/end-to-end-projects',
    component: EndToEndProjectsComponent,
    data: {
      title: 'SERVICES.END_TO_END_PROJECTS.TITLE_TAG',
      metaDescriptionTag: 'SERVICES.END_TO_END_PROJECTS.Meta_Description'
    }
  },
  // {
  //   path: 'services/qa',
  //   component: QAComponent,
  //   data: {
  //     title: 'HEADER.QA',
  //     metaDescriptionTag: 'COMMON.HOME_Meta_Description'
  //   }
  // },
  {
    path: 'services/specialist-support',
    component: SpecialistSupportComponent,
    data: {
      title: 'HEADER.Specialist_Support',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'services/supporting-services',
    component: SupportingComponent,
    data: {
      title: 'SUPPORTING_SERVICES.Speciality_Services_TITLE_TAG',
      metaDescriptionTag: 'SUPPORTING_SERVICES.Speciality_Services_Meta_Description'
    }
  },
  {
    path: 'career/working-at-global',
    component: WorkingAtGlobalComponent,
    data: {
      title: 'HEADER.Working_at_Global_Teams',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'career/open-positions',
    component: OpenPositionsComponent,
    data: {
      title: 'HEADER.Open_Positions',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'terms',
    component: PrivacyComponent,
    data: {
      title: 'HEADER.Open_Positions',
      metaDescriptionTag: 'COMMON.HOME_Meta_Description'
    }
  },
  {
    path: 'career/view-position/:id',
    component: JobViewerComponent,
    data: {
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: "enabledBlocking"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

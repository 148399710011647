import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  // closePrivacy(): void {
  //   this.dialogRef.close();
  // }

}
